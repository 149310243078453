<template>
  <div id="settings">
    <v-tabs center-active>
      <v-tab class="ml-5"><v-icon left>mdi-playlist-edit</v-icon>ステータス名変更</v-tab>
      <v-tab-item>
        <v-container>
          <v-card>
            <v-card-title>ステータス名</v-card-title>
            <v-divider />
            <v-card-text>
              <v-data-table class="clickable" :headers="status.tableHeaders" :items-per-page="-1" :items="status.items" @click:row="openStatusEditDialog" disable-sort hide-default-header hide-default-footer>
                <template v-slot:[`item.status`]="{ item }">
                  {{ item.status }}
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
          <!-- crud dialog -->
          <v-dialog v-model="status.edit.dialog" max-width="500px">
            <v-form ref="form" v-model="status.edit.isFormValid" @submit.prevent>
              <v-card>
                <v-card-title>「{{ status.edit.item.status_original }}」ステータス名変更</v-card-title>
                <v-divider />
                <v-card-text>
                  <v-container>
                    <v-row dense>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field ref="edit_status" class="required" v-model="status.edit.item.status" :rules="[inputRules.required]" counter maxlength="20" label="ステータス名" clearable @change="changeStatusInput"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-divider />
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="closeStatusEditDialog">キャンセル</v-btn>
                  <v-btn v-promise-btn color="primary" :disabled="!status.edit.isFormValid" @click="saveStatusEdit">保存</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-dialog>
        </v-container>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import axios from '@/plugins/axios'

export default {
  name: 'Settings',
  data: () => ({
    status: {
      items: [],
      tableHeaders: [
        { text: 'ステータス名', value: 'status', align: 'start' }
      ],
      edit: {
        dialog: false,
        item: {},
        isFormValid: false
      }
    }
  }),
  watch: {
    'status.edit.dialog': function (newVal) {
      if (newVal === true) {
        this.$nextTick(() => {
          this.$refs.form.$el.querySelector('input').focus()
        })
      } else {
        this.status.edit.item = {}
      }
    }
  },
  methods: {
    readDataFromAPI: function () {
      const self = this
      axios.get(`${process.env.VUE_APP_TOKOTON_API_URL}/api/status_mst`)
        .then(res => {
          self.status.items = res.data.filter(item => item.is_cancel !== true)
        })
        .catch(err => {
          console.log(err)
        })
    },
    openStatusEditDialog: function (item) {
      this.status.edit.item = Object.assign({}, item)
      this.status.edit.dialog = true
    },
    closeStatusEditDialog () {
      this.status.edit.dialog = false
    },
    changeStatusInput () {
      if (this.status.edit.item.status === null) {
        this.status.edit.item.status = this.status.edit.item.status_original
      }
    },
    saveStatusEdit () {
      const self = this
      if (self.$refs.form.validate()) {
        return new Promise(function (resolve, reject) {
          const formData = new FormData()
          formData.append('status_id', self.status.edit.item.id)
          formData.append('status', self.status.edit.item.status)
          axios.post(`${process.env.VUE_APP_TOKOTON_API_URL}/api/admin_company_status_mst`, formData)
            .then(res => {
              console.log(res)
              self.$store.commit('setFlashSuccess', 'ステータス名を変更しました。')
              resolve(res)
            })
            .catch(err => {
              console.log(err)
              self.$store.commit('setFlashError', 'ステータス名の変更に失敗しました。')
              reject(err)
            })
            .finally(() => {
              self.readDataFromAPI()
              self.status.edit.dialog = false
            })
        })
      }
    }
  },
  created () {
    this.readDataFromAPI()
  }
}
</script>
